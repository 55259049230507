import axios from 'axios'
// import axiosComapny from '../../../utils/axiosCompany'
import constants from '@/constants'

export default {
  namespaced: true,
  state: {
    users: '',
  },
  getters: {},
  mutations: {
    setUser(state, response) {
      state.users = response.data.data
    },
  },
  actions: {
    getVendors(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/auto-complate-vendor-list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getVendorSearch(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/auto-complate-vendor-list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getDepartments() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${constants.WEB_API_PREFIX}/master-admin/department-list`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getCustomersTypeList() {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.WEB_API_PREFIX}/master-admin/customer-type-list`, '', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getLeadById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/lead-generate-get-by-id`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getLeadDataById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/get-data-by-lead-id`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    createLead(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/save-lead-generation`, payload, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateLead(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/update-lead-generation`, payload, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    ListLeadAPI(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/list-lead-generation`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    ListPendingLeadAPI(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/pending-lead-list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    ListAssignedLeadAPI(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/assigned-lead-list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getLeadContactData(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/get-contact-list`, payload, {
            params: {
              page: payload.pageNumber,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    autocompleteAssignUserList(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/autocomplated-assigned-user-list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
