import axios from 'axios'
import constants from '@/constants'

export default {
  namespaced: true,
  state: {
    users: '',
  },
  getters: {},
  mutations: {},
  actions: {
    importVendor(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(payload.url, payload.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getVendorList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/vendor/list-vendor`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getVendorById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/vendor/vendor-data-by-vcode`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    updateVendor(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/vendor/update-vendor-basic-detail`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getVendorUnitList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/vendor/vendor-unit-list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    closeLead(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/close-lead`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    closeOpportunity(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/close-opportunity`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    convertToOpportunity(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/convert-opportunity`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    leadStepStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/lead-status-list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    assignServicesUserList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/company-user/user-service-list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    assignServicesUserNotInList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/company-user/user-list-notin-service`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getAssignedServices(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/assigned-service-list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getContactById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/get-lead-contact-by-id`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getOppContactById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/get-opportunity-contact-by-id`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    assignServiceSubmit(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/assign-lead`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getVendorContactData(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/vendor/vendor-contact-list`, payload, {
            params: {
              page: payload.pageNumber,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getAssignLog(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/get-assigned-log-list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getOpportunityContactData(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/opporunity-contact-detail`, payload, {
            params: {
              page: payload.pageNumber,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateContactData(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/update-lead-contact-detail`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    createContactData(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/save-lead-contact-detail`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateOpportunityContactData(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/update-opportunity-contact`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    createOpportunityContactData(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/save-opportunity-contact`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addDocument(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/save-lead-document`, payload, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getDocuments(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/get-lead-document`, payload, {
            params: {
              page: payload.pageNumber,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getOpportunityDocuments(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/get-opportunity-document`, payload, {
            params: {
              page: payload.pageNumber,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getRemarks(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/get-lead-remarks`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getOpportunityRemarks(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          // .post(`${constants.COMPANY_API_PREFIX}/lead-generate/get-lead-remarks`, payload, {
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/get-lead-opportunity-remarks`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addRemark(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/save-lead-remark`, payload, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addOpportunityRemark(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          // .post(`${constants.COMPANY_API_PREFIX}/lead-generate/save-lead-remark`, payload, {
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/save-lead-opportunity-remark`, payload, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addOpportunityDocument(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          // .post(`${constants.COMPANY_API_PREFIX}/lead-generate/save-lead-remark`, payload, {
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/save-opportunity-document`, payload, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getReminders(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/get-lead-reminders`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addContacts(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/update-lead-contact-detail`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
