import axios from 'axios'
import constants from '@/constants'

export default {
  namespaced: true,
  actions: {
    GetUserList(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/company-user/matrix-user-list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    GetActivityReport(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/report/activity-report`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },

}
