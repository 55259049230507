// import checkPermission from '@/utils/utils'

const routes = [
  {
    path: '/admin/dashboard',
    name: 'admin/dashboard',
    component: () => import('@/views/SuperAdmin/Dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      key: '2001',
      pageTitle: 'Admin Dashboard',
      breadcrumb: [
        {
          text: 'Admin Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master-user-management/user/list',
    name: 'master-user-management/user/list',
    component: () => import('@/views/SuperAdmin/CreateCompanyForm/UserList.vue'),
    meta: {
      requiresAuth: true,
      key: '2002',
      pageTitle: 'User List',
      breadcrumb: [
        {
          text: 'Master User Management',
          active: false,
          to: '/master-user-management/user/list',
        },
        {
          text: 'User List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master-user-management/user/create',
    name: 'create-users',
    component: () => import('@/views/SuperAdmin/CreateUserForm/CreateUsers.vue'),
    meta: {
      requiresAuth: true,
      key: '2000',
      pageTitle: 'Create Users',
      breadcrumb: [
        {
          text: 'Master User Management',
          active: false,
          to: '/master-user-management/user/list',
        },
        {
          text: 'Create Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/user/edit-user/:id',
    name: 'admin/user/edit-user',
    component: () => import('@/views/SuperAdmin/CreateUserForm/CreateUsers.vue'),
    // beforeEnter: (to, from, next) => {
    //   checkPermission(to, from, next)
    // },
    meta: {
      key: '2000',
      requiresAuth: true,
      pageTitle: 'Create Company',
      breadcrumb: [
        {
          text: 'Master User Management',
          active: false,
          to: '/master-user-management/user/list',
        },
        {
          text: 'Create Company',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master-company-management/company/list',
    name: 'master-company-management/company/list',
    component: () => import('@/views/SuperAdmin/CreateCompanyForm/CompanyList.vue'),
    // beforeEnter: (to, from, next) => {
    //   checkPermission(to, from, next)
    // },
    meta: {
      requiresAuth: true,
      key: '10002',
      pageTitle: 'Company List',
      breadcrumb: [
        {
          text: 'Master Company Management',
          active: false,
        },
        {
          text: 'Company List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master-company-management/company/edit-company/:id',
    name: 'master-company-management/company/edit-company',
    component: () => import('@/views/SuperAdmin/CreateCompanyForm/CreateCompany.vue'),
    // beforeEnter: (to, from, next) => {
    //   checkPermission(to, from, next)
    // },
    meta: {
      requiresAuth: true,
      key: '10001',
      pageTitle: 'Edit Company',
      breadcrumb: [
        {
          text: 'Master Company Management',
          active: false,
          to: '/master-company-management/company/list',
        },
        {
          text: 'Edit Company',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master-company-management/company/create-company',
    name: 'master-company-management/company/create-company',
    component: () => import('@/views/SuperAdmin/CreateCompanyForm/CreateCompany.vue'),
    // beforeEnter: (to, from, next) => {
    //   checkPermission(to, from, next)
    // },
    meta: {
      requiresAuth: true,
      key: '10000',
      pageTitle: 'Create Company',
      breadcrumb: [
        {
          text: 'Master Company Management',
          active: false,
          to: '/master-company-management/company/list',
        },
        {
          text: 'Add Company',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/service-list',
    name: 'master/service-list',
    component: () => import('@/views/SuperAdmin/Services/ServiceList.vue'),
    // beforeEnter: (to, from, next) => {
    //   checkPermission(to, from, next)
    // },
    meta: {
      requiresAuth: true,
      key: '0',
      pageTitle: 'Services List',
      breadcrumb: [
        {
          text: 'Master',
          active: false,
        },
        {
          text: 'Services List',
          active: true,
        },
      ],
    },
  },
]

export default routes
