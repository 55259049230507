const routes = [
  {
    path: '/reports/activity',
    name: 'reports/activity',
    component: () => import('@/views/Company/Reports/ActivityReport.vue'),
    meta: {
      requiresAuth: true,
      key: '8001',
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Reports',
          active: false,
        },
        {
          text: 'Activity Reports',
          active: true,
        },
      ],
    },
  },

]

export default routes
