const routes = [
  {
    path: '/template-list',
    name: 'template-list',
    component: () => import('@/views/Company/FormQuestions/TemplateList.vue'),
    meta: {
      requiresAuth: true,
      key: '4001',
      pageTitle: 'Templates',
      breadcrumb: [
        {
          text: 'Template',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
]

export default routes
