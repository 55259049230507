import axios from 'axios'
import constants from '@/constants'

export default {
  namespaced: true,
  state: {
    users: '',
  },
  getters: {},
  mutations: {

  },
  actions: {
    getModuleList() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${constants.WEB_API_PREFIX}/master-admin/modules-list`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getCountryList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${constants.WEB_API_PREFIX}/master-admin/country-list`, { payload }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getStateList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.WEB_API_PREFIX}/master-admin/state-list`, { country_id: payload.country_id }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getCityList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.WEB_API_PREFIX}/master-admin/city-list`, { state_id: payload.state_id }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getCityAuto(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.WEB_API_PREFIX}/master-admin/city-autocomplete-list`, { city: payload.city }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
