/* eslint-disable prefer-const */
import Vue from 'vue'
import moment from 'moment'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import constants from '@/constants'

export const periodOptions = {
  en: [
    {
      label: 'TODAY',
      value: '1',
    },
    {
      label: 'Yesterday',
      value: '2',
    },
    {
      label: 'This Week',
      value: '3',
    },
    {
      label: 'This Month',
      value: '4',
    },
    {
      label: 'This Year',
      value: '5',
    },
    {
      label: 'Custom',
      value: '6',
    },
    {
      label: 'Any',
      value: '7',
    },
  ],
  hi: [
    {
      label: 'आज',
      value: '1',
    },
    {
      label: 'कल',
      value: '2',
    },
    {
      label: 'इस सप्ताह',
      value: '3',
    },
    {
      label: 'इस महीने',
      value: '4',
    },
    {
      label: 'इस साल',
      value: '5',
    },
    {
      label: 'रीति',
      value: '6',
    },
    {
      label: 'कोई भी',
      value: '7',
    },
  ],
  guj: [
    {
      label: 'આજે',
      value: '1',
    },
    {
      label: 'કાલે',
      value: '2',
    },
    {
      label: 'આ અઠવાડિયે',
      value: '3',
    },
    {
      label: 'આ મહિને',
      value: '4',
    },
    {
      label: 'આ વર્ષ',
      value: '5',
    },
    {
      label: 'કસ્ટમ',
      value: '6',
    },
    {
      label: 'કોઈપણ',
      value: '7',
    },
  ],
}

export const tableFilter = {
  perPage: 5,
  pageOptions: [5, 10, 20, 50, 100, 200, 300, 500],
  totalRows: 1,
  currentPage: 1,
  sortBy: '',
  sortDesc: false,
  sortDirection: 'asc',
  filter: null,
  filterOn: [],
}

export const status = [
  {
    title: 'Active',
    value: 'A',
  },
  {
    title: 'In-Active',
    value: 'I',
  },
]
export const catProId = [1, 2]

export const images = ['JPG', 'PNG', 'GIF', 'WEBP', 'TIFF', 'PSD', 'RAW', 'BMP', 'HEIF', 'INDD', 'jpg', 'png', 'gif', 'webp', 'tiff', 'psd', 'raw', 'bmp', 'heif', 'indd']

export const docs = ['DOC', 'DOCX', 'doc', 'docx', 'XLS', 'XLSX', 'xls', 'xlsx', 'CSV', 'csv']

export const pdf = ['pdf']

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
export const monthsfull = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'Sepember', 'October', 'November', 'December']

export function successToast(msg) {
  Vue.$toast({
    component: ToastificationContentVue,
    position: 'top-right',
    props: {
      title: `${msg}`,
      icon: 'Success',
      variant: 'success',
      text: '',
    },
  })
}

export function getFileExtension(file) {
  // eslint-disable-next-line prefer-const
  return file.split('.').pop()
}

export function failedToast(msg) {
  Vue.$toast({
    component: ToastificationContentVue,
    position: 'top-right',
    props: {
      title: `${msg}`,
      icon: 'Danger',
      variant: 'danger',
      text: '',
    },
  })
}

export function dateRegFormat(date) {
  return moment(new Date(date)).format('DD-MM-YYYY h:mm A')
}

export function activityDateFormat(date) {
  // eslint-disable-next-line prefer-const
  let d = new Date(date)
  // eslint-disable-next-line prefer-const
  // eslint-disable-next-line radix
  let hrs = parseInt(d.getHours())
  const ampm = d.getHours() >= 12 ? 'pm' : 'am'
  hrs %= 12
  // eslint-disable-next-line prefer-const
  let min = `${(`0${d.getMinutes()}`).slice(-2)}`
  hrs = hrs !== 0 ? hrs : 12
  hrs = (`0${hrs}`).slice(-2)
  const ddmm = `${d.getDate()} ${months[d.getMonth()]}`
  return `${hrs}:${min} ${ampm} | ${ddmm}`
}

// export function encrypt(text) {
//   let strData = JSON.stringify(text)
//   return Vue.CryptoJS.AES.encrypt(strData, constants.SHA512_ENCRYPT_KEY).toString()
// }
// export function decrypt(text) {
//   let jsonData = JSON.parse(Vue.CryptoJS.AES.decrypt(text, constants.SHA512_ENCRYPT_KEY).toString(Vue.CryptoJS.enc.Utf8))
//   return jsonData
// }
export function encrypt(text) {
  let strData = JSON.stringify(text)
  return Vue.CryptoJS.HmacSHA256(strData, constants.SHA256_ENCRYPT_KEY).toString()
}
export function decrypt(text) {
  let jsonData = JSON.parse(Vue.CryptoJS.AES.decrypt(text, constants.SHA256_ENCRYPT_KEY).toString(Vue.CryptoJS.enc.Utf8))
  return jsonData
}

// eslint-disable-next-line import/no-mutable-exports
export default class userFilter {
  params= {
    user_type: '',
    city: '',
    status: '',
    email: '',
    name: '',
    username: '',
  }

  size= 10

  totalElements= 0

  totalPages= 1

  sortBy= ''

  sortOrder= ''
}

export function onlyNumber($event) {
  if (!/\d/.test($event.key) && $event.key !== '.') $event.preventDefault()
}

export function getDateByPeriod(val) {
  // eslint-disable-next-line no-case-declarations
  const today = new Date()
  // eslint-disable-next-line no-unused-vars
  let startdate; let enddate; let dd; let edd; let mm; let
    yyyy
  // eslint-disable-next-line no-unused-vars
  let isDisable = true
  switch (val) {
    case '1':
      // eslint-disable-next-line no-case-declarations
      dd = String(today.getDate()).padStart(2, '0')
      edd = String(today.getDate()).padStart(2, '0')
      // eslint-disable-next-line no-case-declarations
      mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      // eslint-disable-next-line no-case-declarations
      yyyy = today.getFullYear()
      startdate = `${yyyy}-${mm}-${dd}`
      enddate = `${yyyy}-${mm}-${edd}`
      break
    case '2':
      // eslint-disable-next-line no-case-declarations
      let startDataTime2 = new Date(today.getTime() - (1 * 24 * 60 * 60 * 1000))
      // eslint-disable-next-line no-case-declarations
      dd = String(startDataTime2.getDate()).padStart(2, '0')
      edd = String(startDataTime2.getDate()).padStart(2, '0')
      // eslint-disable-next-line no-case-declarations
      mm = String(startDataTime2.getMonth() + 1).padStart(2, '0') // January is 0!
      // eslint-disable-next-line no-case-declarations
      yyyy = startDataTime2.getFullYear()
      startdate = `${yyyy}-${mm}-${dd}`
      enddate = `${yyyy}-${mm}-${edd}`
      break
    case '3':
      // eslint-disable-next-line no-case-declarations
      // let startDataTime3 = new Date(today.getTime() - 7)
      // eslint-disable-next-line no-case-declarations
      // dd = String(today.getDate() - 7).padStart(2, '0')
      // dd = String(startDataTime3.getDate()).padStart(2, '0')
      // edd = String(today.getDate()).padStart(2, '0')
      // eslint-disable-next-line no-case-declarations
      // mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      // eslint-disable-next-line no-case-declarations
      // let styyyy = startDataTime3.getFullYear()
      // eslint-disable-next-line no-case-declarations
      // let stmm = String(startDataTime3.getMonth() + 1).padStart(2, '0')
      // yyyy = today.getFullYear()
      // startdate = `${styyyy}-${stmm}-${dd}`
      startdate = moment().startOf('week').format('YYYY-MM-DD')
      // enddate = `${yyyy}-${mm}-${edd}`
      enddate = moment().endOf('week').format('YYYY-MM-DD')
      break
    case '4':
      // eslint-disable-next-line no-case-declarations
      // dd = String(today.getDate()).padStart(2, '0')
      // // eslint-disable-next-line no-case-declarations
      // mm = String(today.getMonth()).padStart(2, '0') // January is 0!
      // edd = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      // // eslint-disable-next-line no-case-declarations
      // yyyy = today.getFullYear()
      // startdate = `${yyyy}-${mm}-${dd}`
      // enddate = `${yyyy}-${edd}-${dd}`
      startdate = moment().startOf('month').format('YYYY-MM-DD')
      enddate = moment().endOf('month').format('YYYY-MM-DD')
      break
    case '5':
      // eslint-disable-next-line no-case-declarations
      // dd = String(today.getDate()).padStart(2, '0')
      // // eslint-disable-next-line no-case-declarations
      // mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      // // eslint-disable-next-line no-case-declarations
      // yyyy = today.getFullYear()
      // edd = String(today.getFullYear() - 1).padStart(2, '0') // January is 0!
      startdate = moment().startOf('year').format('YYYY-MM-DD')
      enddate = moment().endOf('year').format('YYYY-MM-DD')
      // startdate = `${edd}-${mm}-${dd}`
      // enddate = `${yyyy}-${mm}-${dd}`
      break
    case '6':
      // eslint-disable-next-line no-case-declarations
      dd = String(today.getDate()).padStart(2, '0')
      // eslint-disable-next-line no-case-declarations
      mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      // eslint-disable-next-line no-case-declarations
      yyyy = today.getFullYear()
      startdate = `${yyyy}-${mm}-${dd}`
      enddate = `${yyyy}-${mm}-${dd}`
      isDisable = false
      break
    case '7':
      // eslint-disable-next-line no-case-declarations
      dd = String(today.getDate()).padStart(2, '0')
      // eslint-disable-next-line no-case-declarations
      mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      // eslint-disable-next-line no-case-declarations
      yyyy = today.getFullYear()
      startdate = ''
      enddate = ''
      isDisable = false
      break
    default:
      break
  }
  return [startdate, enddate, isDisable]
}

export function showLoader() {
  document.getElementById('loading-bg').classList.add('loading-bg')
}
export function hideLoader() {
  document.getElementById('loading-bg').classList.remove('loading-bg')
}

export const userTabelFieldsNoPassword = [
  { key: 'companyuserid', label: 'id', sortable: true },
  { key: 'profile_pic', label: 'Photo' },
  { key: 'username', label: 'Username', sortable: true },
  { key: 'email', label: 'Email', sortable: true },
  { key: 'firstname', label: 'FirstName', sortable: true },
  { key: 'lastname', label: 'LastName', sortable: true },
  { key: 'city_name', label: 'City Name', sortable: true },
  { key: 'usertype', label: 'Usertype', sortable: true },
  { key: 'status', label: 'Status', sortable: true },
]
