const routes = [
  {
    path: '/template/questions-list',
    name: 'template/questions-list',
    component: () => import('@/views/Company/FormQuestions/QuestionsList.vue'),
    meta: {
      requiresAuth: true,
      key: '5001',
      pageTitle: 'Questions List',
      breadcrumb: [
        {
          text: 'Template',
          active: false,
          to: '/template-list',
        },
        {
          text: 'Questions List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template/questions-list/:id',
    name: 'questions-list/',
    component: () => import('@/views/Company/FormQuestions/QuestionsList.vue'),
    meta: {
      requiresAuth: true,
      key: '5001',
      pageTitle: 'Questions List',
      breadcrumb: [
        {
          text: 'Template',
          active: false,
          to: '/template-list',
        },
        {
          text: 'Questions List',
          active: true,
        },
      ],
    },
  },
]

export default routes
