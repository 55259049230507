export default function checkPermission(to) {
  // eslint-disable-next-line eqeqeq
  if (to.meta.key == '0') {
    return true
  }
  if (localStorage.getItem('permission') == null) {
    return false
  }
  const keyArr = localStorage.getItem('permission').split(',')
  let isValidPermission = false
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keyArr.length; i++) {
    if (to.meta.key === keyArr[i]) {
      isValidPermission = true
    }
  }
  if (!isValidPermission) {
    return false
  }
  return true
}
