/* eslint-disable no-unused-vars */
import axios from 'axios'
import routes from '@/router/superAdmin'
import constants from '@/constants'

export default {
  namespaced: true,
  state: {
    users: '',
  },
  getters: {},
  mutations: {
    setUser(state, response) {
      state.users = response.data.data
    },
  },
  actions: {
    adminLogout(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.WEB_API_PREFIX}/master-admin/admin-logout`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getCompanyList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.WEB_API_PREFIX}/master-admin/company/list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
            commit('setUser', response)
          })
          .catch(error => reject(error))
      })
    },
    getCompanybyId(ctx, payload) {
      const { company_id } = payload
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.WEB_API_PREFIX}/master-admin/company/getById`, { company_id }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    createCompany(ctx, payload) {
      const { data } = payload
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.WEB_API_PREFIX}/master-admin/company/create`, data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updataCompany(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.WEB_API_PREFIX}/master-admin/company/update`, payload.data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getUserList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.WEB_API_PREFIX}/admin/user/list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
