const routes = [
  {
    path: '/vendor/:id/unit-list',
    name: 'vendor/unit-list',
    component: () => import('@/views/Company/Vendor/Units/UnitList.vue'),
    meta: {
      requiresAuth: true,
      key: '3001',
      pageTitle: 'Manage Units',
      breadcrumb: [
        {
          text: 'Units',
          active: false,
          to: '/vendors/unit-list',
        },
        {
          text: 'Unit List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-unit',
    name: 'add-unit',
    component: () => import('@/views/Company/Vendor/Units/AddUnit.vue'),
    meta: {
      requiresAuth: true,
      key: '3001',
      pageTitle: 'Add Units',
      breadcrumb: [
        {
          text: 'Units',
          active: false,
          to: '/vendors/unit-list',
        },
        {
          text: 'Add Unit',
          active: true,
        },
      ],
    },
  },
]

export default routes
