import Vue from 'vue'
import bootstrapVue, { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import * as VueGoogleMaps from 'vue2-google-maps'
import sweetAlerts from 'vue-sweetalert2'
import CoolLightBox from 'vue-cool-lightbox'
import VueCryptojs from 'vue-cryptojs'
import axios from 'axios'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// eslint-disable-next-line import/no-extraneous-dependencies
import '@fortawesome/fontawesome-free/js/all'

import 'bootstrap/dist/css/bootstrap.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fortawesome/fontawesome-free/css/all.css'
import constants from './constants'
import excel from './excel'
import './utils/axios'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import 'vue-pure-lightbox/dist/VuePureLightbox.css'
import { hideLoader } from './utils/common'

Vue.component('pagination', require('laravel-vue-pagination'))

Vue.use(CoolLightBox)
Vue.use(sweetAlerts)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAP_KEY,
    libraries: 'places',
  },
  installComponents: true,
})

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(bootstrapVue)
Vue.use(constants)
Vue.use(excel)
Vue.use(VueCryptojs)

// Composition API
Vue.use(VueCompositionAPI)
Vue.filter('uppercase', value => value.toUpperCase())

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.filter(
  'arrayToStringVal', value => {
    let result = ''
    if (!value) return ''
    value.forEach((element, i) => {
      if (i === 0) {
        result += element.lead_assign_name
      } else {
        result += `, ${element.lead_assign_name}`
      }
    })
    return result
  },
)
Vue.filter(
  'arrayToStr', value => {
    let result = ''
    if (!value) return ''
    value.forEach((element, i) => {
      if (i === 0) {
        result += element
      } else {
        result += `, ${element}`
      }
    })
    return result
  },
)

axios.interceptors.response.use(response => response, error => {
  if (error.response && error.response.data) {
    // eslint-disable-next-line eqeqeq
    if (error.response.status == 401) {
      localStorage.clear()
      router.push({ path: '/login', query: { t: true } })
      hideLoader()
    }
    return Promise.reject(error.response.data)
  }
  return Promise.reject(error.message)
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
