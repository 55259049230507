import axios from 'axios'
import constants from '@/constants'

export default {
  namespaced: true,
  state: {
    users: '',
  },
  getters: {},
  mutations: {},
  actions: {
    getPermissionSettings() {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.WEB_API_PREFIX}/admin/user/show-rights`, '', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getUsers() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${constants.WEB_API_PREFIX}/admin/user/show-user-name`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getUserTypePermissions(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.WEB_API_PREFIX}/admin/user/show-user-rights`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    saveUserPermission(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.WEB_API_PREFIX}/admin/user/edit-user-rights`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    saveUserTypePermissions(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.WEB_API_PREFIX}/admin/user/edit-user-rights`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
