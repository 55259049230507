import axios from 'axios'
import constants from '@/constants'
// import { encrypt } from '@/utils/common'

export default {
  namespaced: true,
  actions: {
    getGroupList(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/common/group-list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getTypeList(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/common/type-list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getTemplateDD(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/template/get-list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getTemplateList(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/template/list-template`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getQuestionList(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/template/get-question`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getTemplateJSON(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/template/display-template-question-option`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getQuestionById(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/template/get-question-by-id`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getMasterTable(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/common/master-table-list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    saveTemplate(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/template/save-template`, payload.payload, {
            headers: {
              'x-secret-key': payload.secretKey,
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    saveQuestion(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/template/save-question`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    changeStatusTemplate(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/template/change-template-status`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    changeStatusQuestion(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/template/change-question-status`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateQuestion(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/template/update-question`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    saveQuestionAnswers(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/template/save-template-question-information`, payload, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getQuestionAnswers(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/template/get-template-question-information`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getPDFQnA(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/template/generate-template-question-pdf`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    saveStateQuantityCategory(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/template/save-state-quantity-category`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getStateQuantityCategory(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/template/get-state-quantity-category`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    importExcelData(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/template/import-state-quantity-category`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getRepeaterQuestionAnswers(cts, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/template/get-repeter-question-info`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
