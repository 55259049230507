import axios from 'axios'
import constants from '@/constants'

export default {
  namespaced: true,
  state: {
    users: '',
  },
  getters: {},
  mutations: {},
  actions: {
    changeAdminProfile(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.WEB_API_PREFIX}/admin/user/change-profile`, payload, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    changeProfile(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/company-user/change-profile`, payload, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    changeAdminPassword(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.WEB_API_PREFIX}/admin/user/change-password`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    changePassword(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/company-user/change-password`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    forgot(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/forgot-password`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    adminForgot(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.WEB_API_PREFIX}/master-admin/forgot-password`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    setPassword(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.WEB_API_PREFIX}/admin/user/reset-password`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

  },
}
