const routes = [
  {
    path: '/vendors/list-vendor',
    name: 'vendors/list-vendor',
    component: () => import('@/views/Company/Vendor/VendorList.vue'),
    meta: {
      requiresAuth: true,
      key: '3001',
      pageTitle: 'Manage Vendors',
      breadcrumb: [
        {
          text: 'Vendors',
          active: false,
          to: '/vendors/list-vendor',
        },
        {
          text: 'Vendors List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vendor/:id',
    name: 'vendor/id',
    component: () => import('@/views/Company/Vendor/VendorDetails.vue'),
    meta: {
      requiresAuth: true,
      key: '3003',
      pageTitle: 'Vendor Details',
      breadcrumb: [
        {
          text: 'Vendors',
          active: false,
          to: '/vendors/list-vendor',
        },
        {
          text: 'Vendor Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vendor-edit/:id',
    name: 'vendor-edit/id',
    component: () => import('@/views/Company/Vendor/VendorEdit.vue'),
    meta: {
      requiresAuth: true,
      key: '3003',
      pageTitle: 'Vendor Edit',
      breadcrumb: [
        {
          text: 'Vendors',
          active: false,
          to: '/vendors/list-vendor',
        },
        {
          text: 'Edit Vendor',
          active: true,
        },
      ],
    },
  },
]

export default routes
