import Vue from 'vue'
import VueRouter from 'vue-router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import checkPermission from '../utils/utils'
import superRoutes from './superAdmin'
import companyRoutes from './Company'
import constants from '@/constants'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'login' } },
    ...superRoutes,
    ...companyRoutes,
    {
      path: '/auto-login/:code/:timestamp',
      name: 'auto-login',
      component: () => import('@/views/Company/Redirect.vue'),
      meta: {
        key: '0',
        requiresAuth: false,
        layout: 'full',
        pageTitle: 'Redirecting...',
      },
    },
    {
      path: '/link-expired',
      name: 'link-expired',
      component: () => import('@/views/error/LinkExpired.vue'),
      meta: {
        key: '0',
        requiresAuth: false,
        layout: 'full',
        pageTitle: 'Link Expired',
      },
    },
    {
      path: '/reset-password',
      name: 'auth-reset-password',
      component: () => import('@/views/PasswordReset/ResetPassword.vue'),
      meta: {
        requiresAuth: false,
        layout: 'full',
        pageTitle: 'Reset Password',
      },
    },
    {
      path: '/forgot-password',
      name: 'auth-forgot-password',
      component: () => import('@/views/PasswordReset/ForgotPassword.vue'),
      meta: {
        requiresAuth: false,
        layout: 'full',
        pageTitle: 'Forgot Password',
      },
    },
    {
      path: '/admin/forgot-password',
      name: 'admin-forgot-password',
      component: () => import('@/views/PasswordReset/ForgotPassword.vue'),
      meta: {
        requiresAuth: false,
        pageTitle: 'Admin Password Forgot',
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Company/Login.vue'),
      meta: {
        requiresAuth: false,
        layout: 'full',
        pageTitle: 'User Login',
      },
    },
    {
      path: '/admin',
      name: 'login-admin',
      component: () => import('@/views/Login.vue'),
      meta: {
        pageTitle: 'Admin Login',
        requiresAuth: false,
        layout: 'full',
      },
    },
    {
      path: '/manage-users/permission-setting',
      name: 'manage-users/permission-setting',
      component: () => import('@/views/ManageUsersPermissions/UserTypePermissions.vue'),
      // beforeEnter: (to, from, next) => {
      //   checkPermission(to, from, next)
      // },
      meta: {
        requiresAuth: true,
        key: '1004',
        pageTitle: 'Permission Setting',
        breadcrumb: [
          {
            text: 'Manage Users',
            active: false,
          },
          {
            text: 'Permission Settings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/PasswordReset/Profile.vue'),
      meta: {
        key: '0',
        requiresAuth: true,
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: () => import('@/views/PasswordReset/ChangePassword.vue'),
      meta: {
        key: '0',
        requiresAuth: true,
        pageTitle: 'Change Password',
        breadcrumb: [
          {
            text: 'Change Passowrd',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        key: '0',
        requiresAuth: true,
        pageTitle: 'Error 404',
        layout: 'full',
      },
    },
    {
      path: '/unauthorized',
      name: 'error-403',
      component: () => import('@/views/error/Error403.vue'),
      meta: {
        key: '0',
        requiresAuth: true,
        pageTitle: 'Unauthorized',
        layout: 'full',
      },
    },
    {
      path: '*',
      name: 'nothing',
      meta: {
        key: '0',
        requiresAuth: true,
        pageTitle: 'Error 404',
        layout: 'full',
      },
      redirect: 'error-404',
    },
  ],
})

function isLoggedIn() {
  return !!localStorage.getItem('accessToken')
}
function userType() {
  if (localStorage.getItem('userData') === null) {
    return null
  }
  return JSON.parse(localStorage.getItem('userData')).user_type
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // eslint-disable-next-line eqeqeq
    // if (to.name == 'profile' || to.name == 'change-password') {
    //   return next()
    // }

    if (isLoggedIn() && checkPermission(to)) {
      return next()
    }
    // eslint-disable-next-line eqeqeq
    if (to.name == 'nothing') {
      return router.push('error-404')
    }
    if (!checkPermission(to)) {
      Vue.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Unauthorized',
          icon: 'Danger',
          variant: 'danger',
          text: '',
        },
      })
      return router.push('/unauthorized')
    }
    return router.push('/')
  }
  if (!to.matched.some(record => record.meta.requiresAuth)) {
    // eslint-disable-next-line space-infix-ops
    // eslint-disable-next-line eqeqeq
    if (isLoggedIn() && userType() !== null && userType() == '10') {
      router.push({ path: '/admin/dashboard' })
    // eslint-disable-next-line eqeqeq
    } else if (isLoggedIn() /* && userType() == '1' */) {
      router.push({ path: '/dashboard' })
    }
  }
  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(to => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  Vue.nextTick(() => {
    document.title = `IMS - ${to.meta.pageTitle}` || constants.DEFAULT_TITLE
  })
})

export default router
