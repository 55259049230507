import axios from 'axios'

export default {
  namespaced: true,
  actions: {
    roleActions(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(payload.url, payload.data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
