// import checkPermission from '@/utils/utils'

const routes = [
  {
    path: '/leads',
    name: 'leads/',
    component: () => import('@/views/Company/Leads/CreateLeads/LeadList.vue'),
    meta: {
      requiresAuth: true,
      key: '2001',
      pageTitle: 'Leads',
      breadcrumb: [
        {
          text: 'Leads',
          active: false,
        },
        {
          text: 'Leads List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/leads/add-lead',
    name: 'add-lead',
    component: () => import('@/views/Company/Leads/CreateLeads/CreateLead.vue'),
    meta: {
      requiresAuth: true,
      key: '0',
      pageTitle: 'Leads',
      breadcrumb: [
        {
          text: 'Leads',
          active: false,
          to: '/leads',
        },
        {
          text: 'Add New Lead',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lead/:id',
    name: 'assignedlead/id',
    component: () => import('@/views/Company/Leads/LeadDetails/LeadDetails.vue'),
    meta: {
      requiresAuth: true,
      key: '3003',
      pageTitle: 'Lead Details',
      breadcrumb: [
        {
          text: 'Leads',
          active: false,
          to: '/leads',
        },
        {
          text: 'Lead Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lead/:id',
    name: 'lead/id',
    component: () => import('@/views/Company/Leads/LeadDetails/LeadDetails.vue'),
    meta: {
      requiresAuth: true,
      key: '3003',
      pageTitle: 'Lead Details',
      breadcrumb: [
        {
          text: 'Leads',
          active: false,
          to: '/leads',
        },
        {
          text: 'Lead Details',
          active: true,
        },
      ],
    },
  },

  {
    path: '/leads/lead-opportunity-list',
    name: 'leads/lead-opportunity-list',
    component: () => import('@/views/Company/Opportunity/OpportunityList.vue'),
    meta: {
      requiresAuth: true,
      key: '2004',
      pageTitle: 'Opportunity List',
      breadcrumb: [
        {
          text: 'Leads',
          active: false,
          to: '/leads',
        },
        {
          text: 'Opportunity List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/opportunity/:id',
    name: 'opportunity/id',
    component: () => import('@/views/Company/Opportunity/OpportunityDetails.vue'),
    meta: {
      requiresAuth: true,
      key: '2004',
      pageTitle: 'Opportunity Details',
      breadcrumb: [
        {
          text: 'Opportunity',
          active: false,
          to: '/leads/lead-opportunity-list',
        },
        {
          text: 'Opportunity Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/leads/pending-lead-list',
    name: 'leads/pending-lead-list',
    component: () => import('@/views/Company/Leads/LeadDetails/PendingLeads.vue'),
    meta: {
      requiresAuth: true,
      key: '2003',
      pageTitle: 'Pending List',
      breadcrumb: [
        {
          text: 'Leads',
          active: false,
          to: '/leads',
        },
        {
          text: 'Pending List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/leads/assigned-lead-list',
    name: 'leads/assigned-lead-list',
    component: () => import('@/views/Company/Leads/LeadDetails/AssignLeads.vue'),
    meta: {
      requiresAuth: true,
      key: '2003',
      pageTitle: 'Assigned List',
      breadcrumb: [
        {
          text: 'Leads',
          active: false,
          to: '/leads',
        },
        {
          text: 'Assigned List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/leads/assigned-leads',
    name: 'leads/assigned-leads',
    component: () => import('@/views/Company/Leads/LeadDetails/AssignLeads.vue'),
    meta: {
      requiresAuth: true,
      key: '2003',
      pageTitle: 'Assigned Lead Details',
      breadcrumb: [
        {
          text: 'Leads',
          active: false,
          to: '/leads/assigned-lead-list',
        },
        {
          text: 'Assigned Lead Details',
          active: true,
        },
      ],
    },
  },

]

export default routes
