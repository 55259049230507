import * as XLSX from 'xlsx'

export default Object.freeze({
  methods: {
    exportToExcel(tableData) {
      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.aoa_to_sheet(tableData)
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
      XLSX.writeFile(workbook, 'table_data.xlsx')
    },
    exportToExcelJson(tableData, name) {
      const ws = XLSX.utils.json_to_sheet(tableData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Excel')
      XLSX.writeFile(wb, `${name}.xlsx`)
    },
  },
})
